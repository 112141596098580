import React from "react";
import MyNavLink from './my-nav-link'
import './aside.css'

export default class Aside extends React.Component{
    render() {
        return(
            <div id="sidebar">
                <ul className="category-list">
                    <li className="all "><MyNavLink to="/">全部模板</MyNavLink></li>
                    <li className="cat-item category-blog-themes"><MyNavLink
                        to="/race_themes">赛事模板</MyNavLink>
                    </li>
                    <li className="cat-item category-corporate-themes"><MyNavLink
                        to="/org_themes">机构模板</MyNavLink>
                    </li>
                </ul>

                <div className="box">
                    <div  className="banner purple">
                        <h3>专业</h3>
                        <p>专门针对赛事和赛事机构开发</p>
                    </div>
                </div>

                <div className="box">
                    <div  className="banner pink">
                        <h3>高颜值</h3>
                        <p>多种风格模板，可根据赛事主题灵活配置</p>
                    </div>
                </div>

                <div className="box">
                    <div  className="banner green">
                        <h3>多平台</h3>
                        <p>响应式设计，适用电脑平板和手机多个终端</p>
                    </div>
                </div>

                <div className="box">
                    <div  className="banner blue">
                        <h3>自由插件</h3>
                        <p>丰富的插件库，根据赛事需求自由组合</p>
                    </div>
                </div>
            </div>
        )
    }
}