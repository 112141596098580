import React from "react";
import PropTypes from 'prop-types'
import PubSub from 'pubsub-js'
import MyNavLink from './my-nav-link'

export default class ThemesList extends React.Component{
    static  propTypes= {
        themes: PropTypes.array.isRequired,
    }

    showDemo=(e)=>{
        console.log('e:',e)
        PubSub.publish('showdemo',e)
    }
    render() {
        const themes=this.props.themes

        // const showDemo=this.props.showDemo
        const themeslist=themes.map((theme,index)=>
            <li  key={index} className="theme-post  post-4724 post type-post status-publish format-standard hentry category-blog-themes category-corporate-themes category-featured-themes category-portfolio-themes category-responsive-themes category-themes">
                <figure className="theme-image">
                    <span className="theme-frame"><span className="dot"></span><span
                className="dot"></span><span className="dot"></span></span>
                    <MyNavLink to={'/'+theme.id}>
                        <img src={theme.poster} alt="Parallax" />
                    </MyNavLink>
                </figure>
                <div className="theme-info">
                    <div className="theme-title">
                        <h3><MyNavLink to={'/'+theme.id}>{theme.name}</MyNavLink></h3>
                        {/*<a className="tag-button lightbox" href="#" onClick={()=>this.showDemo(theme.src)}>*/}
                        {/*    demo*/}
                        {/*</a>*/}
                    </div>
                    <div className="theme-excerpt">
                        <p>{theme.desc}</p>
                    </div>
                </div>
            </li>)
        return(
            <div>
                <ol className="grid3 theme-list clearfix">
                    {themeslist}
                </ol>
            </div>
        )
    }
}