
import themesinfo from "../../content/themesinfo"
let themes={
    raceThemes:[],
    orgThemes:[],
    allThemes:themesinfo
}
themesinfo.forEach((theme,index)=>{
    if(theme.id.includes("race")){
        themes.raceThemes.push(theme)
    }else {
        themes.orgThemes.push(theme)
    }
})

 export default themes

