import React from "react"
import Layout from "../components/layout"
import Aside from '../components/aside'
import ThemesList from '../components/themes-list'
import themes from '../data/themesInfo'
import PubSub from "pubsub-js";

export default  class OrgThemes extends React.Component {
  componentWillMount() {
    // window.scrollTo(0,0)
  }
  showDemo=(e)=>{
    console.log('e:',e)
    PubSub.publish('showdemo',e)
  }
  render() {
    const orgThemes=themes.orgThemes
    // const siteTitle = data.site.siteMetadata.title
    return (
      <Layout>
        <div className="pagewidth clearfix">
          <div id="content">
            <h1 className="page-title">赛事机构模板</h1>
            <ThemesList themes={orgThemes}/>
          </div>
          <Aside />
        </div>


      </Layout>
    )
  }
}




